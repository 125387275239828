<!-- CornerChatPopup.vue -->
<template>
  <div class="corner-chat-popup">
    <div class="chat-ai-button" v-if="!showPopup" @click="showPopup = true">
    <span class="ai-text">AI</span>
  </div>

    <!-- 弹框 (430x650) -->
    <transition name="fade">
      <div v-if="showPopup" class="popup-box">
        <component
          :is="currentViewComponent"
          :user-info="userInfo"
          :logged-in="loggedIn"
          @close="showPopup = false"
          @goView="onGoView"
          @loginSuccess="onLoginSuccess"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import Home from './components/Home.vue';
import AIChat from './components/AIChat.vue';
import Feedback from './components/Feedback.vue';
import { Toast } from 'vant';

export default {
  name: 'CornerChatPopup',
  components: {
    Login,
    Register,
    Home,
    AIChat,
    Feedback,
  },
  data() {
    return {
      showPopup: false,   // 是否显示弹框
      currentView: 'chat', // 当前页面
      loggedIn: false,    // 是否已登录
      userInfo: {},       // 用户信息
    };
  },
  computed: {
    currentViewComponent() {
      switch (this.currentView) {
        case 'login': return 'Login';
        case 'register': return 'Register';
        case 'home': return 'Home';
        case 'chat': return 'AIChat';
        case 'feedback': return 'Feedback';
        default: return 'Login';
      }
    },
  },
  methods: {
    onGoView(viewName) {
      this.currentView = viewName;
    },
    onLoginSuccess(user) {
      this.loggedIn = true;
      this.userInfo = user;
    },
    // 处理退出登录事件
    onLogout() {
      this.loggedIn = false;
      this.userInfo = {};
      this.currentView = 'login';
    },
  },
};
</script>

<style scoped>
.corner-chat-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}
.floating-button {
  border-radius: 4px;
}
.popup-box {
  width: 400px;
  height: 95vh;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* 动画 */
/* .fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
} */

.chat-ai-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 20px;
}

.ai-text {
  font-size: 48px;
  font-weight: bold;
  background: linear-gradient(90deg, #00e5ff, #bd67ff, #0066ff);
  -webkit-background-clip: text;
  color: transparent;
}
</style>
