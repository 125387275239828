<!-- pages/LoginPage.vue -->
<template>
  <div class="page login-page">
    <!-- 头部，增加返回按钮 -->
    <div class="login-header">
      <van-icon name="arrow-left" class="back-icon" @click="goBack" />
      <h2 class="page-title">登录</h2>
    </div>
    <van-field
      v-model="phone"
      placeholder="请输入手机号"
      left-icon="phone-o"
      clearable
      class="input-field"
    />
    <van-field
      v-model="password"
      type="password"
      placeholder="请输入密码"
      left-icon="lock"
      clearable
      class="input-field"
    />
    <van-button
      type="primary"
      block
      class="submit-button"
      @click="onLogin"
    >
      登录
    </van-button>
    <div class="register-tip" @click="$emit('goView','register')">
      立即注册
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Toast } from 'vant';

export default {
  name: 'LoginPage',
  data() {
    return {
      phone: '',
      password: '',
    };
  },
  methods: {
    goBack() {
      // 返回上一页或跳转到指定页面，例如切换到首页
      this.$emit('goView', 'home');
    },
    onLogin() {
      if (!this.phone || !this.password) {
        Toast('请输入手机号和密码');
        return;
      }
      // 简单手机号格式验证：以1开头的11位数字
      if (!/^1\d{10}$/.test(this.phone)) {
        Toast('请输入正确的手机号');
        return;
      }
      // 调用手机号密码登录接口
      axios
        .post('https://mobile.dingdigit.com/webchat/v1/login/pw', {
          phone: this.phone,
          password: this.password,
        })
        .then((res) => {
          if (res.data && res.data.code === 100) {
            const token = res.data.data.token;
            // 保存 token 到 sessionStorage
            sessionStorage.setItem('token', token);
            Toast.success('登录成功');
            // 通知父组件登录成功，并切换到聊天页面
            this.$emit('loginSuccess', { phone: this.phone, token });
            this.$emit('goView', 'chat');
          } else {
            Toast.fail('登录失败');
          }
        })
        .catch((err) => {
          Toast.fail('登录失败');
          console.error(err);
        });
    },
  },
};
</script>

<style scoped>
.page {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}
.page-title {
  font-size: 22px;
  margin-bottom: 20px;
  text-align: center;
}
.input-field {
  margin-bottom: 15px;
}
.submit-button {
  margin-top: 10px;
}
.register-tip {
  margin-top: 15px;
  text-align: center;
  color: #3f81f8;
  cursor: pointer;
  font-size: 14px;
}
</style>
