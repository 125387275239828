<template>
  <div class="feedback-page">
    <van-icon name="arrow-left" size="18" class="back-icon" @click="$emit('goView','home')" />
    <!-- 顶部标题 -->
    <h2 class="feedback-title">感谢您的反馈</h2>

    <!-- 多行文本输入 -->
    <van-field
      v-model="suggest"
      type="textarea"
      rows="5"
      autosize
      placeholder="请输入您的意见或建议"
      class="feedback-textarea"
    />

    <!-- 图片上传 (不显示预览) -->
    <van-uploader
      v-model="fileList"
      :max-size="5 * 1024 * 1024"
      :max-count="1"
      show-file-list="false"
      :preview-full-image="false"
      class="uploader"
      @oversize="onOversize"
    >
      <!-- 自定义上传区域 -->
      <template #upload>
        <div class="upload-area">
          <van-icon name="photograph" size="24px" class="upload-icon" />
          <div class="upload-text">上传图片（选填，不超过5MB）</div>
        </div>
      </template>
    </van-uploader>

    <!-- 提交按钮 -->
    <van-button
      type="info"
      round
      block
      class="submit-button"
      @click="onSubmit"
    >
      提交
    </van-button>

    <!-- 联系方式 -->
    <div class="contact-info">
      <p>邮件：diszkf@Dingdigit.com</p>
      <p>电话：0512-68599599</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Toast, Icon, Uploader, Field, Button } from 'vant';

export default {
  name: 'FeedbackPage',
  components: {
    'van-icon': Icon,
    'van-uploader': Uploader,
    'van-field': Field,
    'van-button': Button,
  },
  props: {
    // 如果 token 存在于 sessionStorage，可直接读取，也可由父组件传入
    token: {
      type: String,
      default: () => sessionStorage.getItem('token') || ''
    }
  },
  data() {
    return {
      suggest: '',      // 反馈内容
      fileList: [],     // 图片文件列表（不显示预览）
    };
  },
  methods: {
    onOversize() {
      Toast('文件大小不能超过 5MB');
    },
    onSubmit() {
      if (!this.suggest.trim()) {
        Toast('请输入您的意见或建议');
        return;
      }
      // 构造 FormData
      const formData = new FormData();
      formData.append('token', this.token);
      formData.append('suggest', this.suggest);
      if (this.fileList.length > 0) {
        formData.append('img', this.fileList[0].file);
      }

      axios
        .post('https://mobile.dingdigit.com/webchat/v1/user/suggest', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((res) => {
          if (res.data && res.data.code === 100) {
            Toast.success('反馈提交成功');
            // 清空输入与文件
            this.suggest = '';
            this.fileList = [];
            // 提交成功后跳转到“我的”页面
            this.$emit('goView', 'home');
          } else {
            Toast.fail('反馈提交失败');
          }
        })
        .catch((err) => {
          Toast.fail('反馈提交失败');
          console.error(err);
        });
    },
  },
};
</script>

<style scoped>
.feedback-page {
  /* 你可在外部容器控制宽高；若需固定尺寸可自行设置 */
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'PingFang SC', Helvetica, Arial, sans-serif;
}

.back-icon{
  position: absolute;
  left: 15px;
  top: 24px;
}

/* 标题 */
.feedback-title {
  font-size: 20px;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

/* 多行文本输入框 */
.feedback-textarea {
  margin-bottom: 20px;
  /* 给内部的 van-field__control 增加边框等 */
}
.feedback-textarea .van-field__control {
  min-height: 100px; /* 适当增加高度，接近截图效果 */
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px;
  background: #fff;
}

/* 图片上传区域（无预览） */
.uploader {
  margin-bottom: 20px;
}
.upload-area {
  display: flex;
  align-items: center;
}
.upload-icon {
  width: 60px;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.upload-text {
  color: #666;
  font-size: 14px;
}

/* 提交按钮 */
.submit-button {
  margin-bottom: 20px;
  font-size: 16px;
  height: 44px;
}

/* 联系方式 */
.contact-info {
  text-align: center;
  color: #999;
  font-size: 14px;
  line-height: 1.6;
}
</style>
