<!-- HomePage.vue -->
<template>
    <div class="home-view">
      <div class="home-content">
        <MessagePage
          v-if="activeTab === 'message'"
          @close="$emit('close')"
          @goView="$emit('goView',$event)"
        />
        <MinePage
          v-if="activeTab === 'mine'"
          :logged-in="loggedIn"
          :user-info="userInfo"
          @goView="$emit('goView',$event)"
        />
      </div>
      <van-tabbar v-model="activeTab" @change="onTabChange" active-color="#1989fa" inactive-color="#999">
        <van-tabbar-item name="message">
          消息
        </van-tabbar-item>
        <van-tabbar-item name="mine">
          我的
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </template>
  
  <script>
  import MessagePage from './Message.vue';
  import MinePage from './Mine.vue';
  
  export default {
    name: 'HomePage',
    props: {
      loggedIn: Boolean,
      userInfo: Object,
    },
    data() {
      return {
        activeTab: 'message',
      };
    },
    methods: {
      onTabChange(name) {
        this.activeTab = name;
      },
    },
    components: {
      MessagePage,
      MinePage,
    },
  };
  </script>
  
  <style scoped>
  .home-view {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .home-content {
    flex: 1;
    overflow-y: auto;
  }
  /* 覆盖 van-tabbar 的定位，使其在容器内 */
  .van-tabbar {
    position: relative !important;
  }
  </style>
  