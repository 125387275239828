<template>
  <div class="chat-page">
    <!-- 顶部导航 -->
    <div class="chat-header">
      <van-icon name="arrow-left" class="back-icon" @click="$emit('goView','home')" />
      <span class="chat-title">AI聊天</span>
    </div>

    <!-- 聊天内容 -->
    <div class="chat-content" ref="chatContainer">
      <div 
        v-for="(message, index) in messages" 
        :key="index" 
        class="chat-message" 
        :class="{ 'user-message': message.isUser }"
      >
        <!-- 如果是 AI 消息显示头像 -->
        <div v-if="!message.isUser" class="ai-avatar">AI</div>
        <div class="message-box">
          <div class="message-text">
            <!-- 如果正在思考，显示 loading 图标及文字 -->
            <template v-if="message.isThinking">
              <!-- <van-loading size="16px" type="spinner" class="thinking-icon" /> -->
              正在思考...
            </template>
            <template v-else>
              {{ message.text }}
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部输入区域 -->
    <div class="chat-footer">
      <div class="input-container">
        <input
          class="chat-input"
          v-model="inputMessage"
          placeholder="请输入内容"
          @keyup.enter="sendMessage"
          type="text"
        />
      </div>
      <button class="send-btn" @click="sendMessage">
        发送
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Toast } from 'vant';

export default {
  name: "ChatPage",
  props: {
    loggedIn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputMessage: "",
      messages: [
        {
          isUser: false,
          text: "我是惦记大模型，是由苏州鼎级数字技术有限公司自主研发的情感陪伴智能体。我的目标是倾听你的心声，理解你的情感状态，并提供切实有效的情感支持",
        }
      ],
      sessionId: "cf5be66f2c98",
    };
  },
  computed: {
    isLoggedIn() {
      return this.loggedIn || !!sessionStorage.getItem('token');
    },
    token() {
      return sessionStorage.getItem('token');
    }
  },
  methods: {
    async sendMessage() {
      // 如果未登录，提示并跳转到登录页面
      if (!this.isLoggedIn || !this.token) {
        Toast('请先登录');
        this.$emit('goView', 'login');
        return;
      }

      // 如果没有输入内容，则不发送请求
      if (!this.inputMessage.trim()) return;

      // 添加用户消息
      this.messages.push
      if (!this.inputMessage.trim()) return;

      const userMsg = this.inputMessage;
      // 添加用户消息
      this.messages.push({
        isUser: true,
        text: userMsg,
      });
      this.inputMessage = "";
      this.$nextTick(this.scrollToBottom);

      // 添加“正在思考”的过渡消息
      const thinkingMsg = {
        isUser: false,
        isThinking: true,
        text: "",
      };
      this.messages.push(thinkingMsg);
      this.$nextTick(this.scrollToBottom);

      // 构造请求参数
      const payload = {
        token: this.token,
        sessionId: this.sessionId,
        msg: userMsg,
      };

      try {
        const res = await axios.post('https://mobile.dingdigit.com/webchat/v1/chat/send', payload);
        if (res.data && res.data.code === 100) {
          const aiReply = res.data.data.replymMsg || "AI 暂时无法回复，请稍后再试。";
          // 替换“正在思考”消息为 AI 的回复
          const index = this.messages.indexOf(thinkingMsg);
          if (index !== -1) {
            this.$set(this.messages, index, {
              isUser: false,
              text: aiReply,
              isThinking: false,
            });
          } else {
            // 如果未找到，则直接添加回复
            this.messages.push({
              isUser: false,
              text: aiReply,
            });
          }
          this.$nextTick(this.scrollToBottom);
        } else {
          Toast.fail("获取回复失败");
          // 删除“正在思考”消息
          const index = this.messages.indexOf(thinkingMsg);
          if (index !== -1) this.messages.splice(index, 1);
        }
      } catch (error) {
        Toast.fail("获取回复失败");
        console.error(error);
        // 删除“正在思考”消息
        const index = this.messages.indexOf(thinkingMsg);
        if (index !== -1) this.messages.splice(index, 1);
      }
    },
    scrollToBottom() {
      const container = this.$refs.chatContainer;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
  },
};
</script>

<style scoped>
.chat-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
}

/* 顶部导航 */
.chat-header {
  padding: 10px;
  background: white;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  text-align: center;
  position: relative;
}
.back-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
}
.chat-title {
  margin: 0 auto;
}

/* 聊天内容 */
.chat-content {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
}
.chat-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.user-message {
  justify-content: flex-end;
}
.ai-avatar {
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  background: linear-gradient(45deg, #ff66ff, #6699ff);
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
}
.message-box {
  max-width: 70%;
  padding: 10px;
  border-radius: 8px;
}
.user-message .message-box {
  background: #cce5ff;
  text-align: right;
}
.chat-message:not(.user-message) .message-box {
  background: #f2e5ff;
}
.message-text {
  font-size: 14px;
  line-height: 1.5;
}

/* 底部输入区域 */
.chat-footer {
  display: flex;
  align-items: center;
  padding: 10px;
  background: white;
  border-top: 1px solid #ddd;
}
.input-container {
  flex: 1;
  background: #f2f2f2;
  border-radius: 20px;
  padding: 0 15px;
  height: 40px;
  display: flex;
  align-items: center;
}
.chat-input {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  color: #333;
  line-height: 1.5;
}
.send-btn {
  width: 60px;
  height: 40px;
  background: #1989fa;
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
}
.thinking-icon {
  margin-right: 5px;
}
</style>
