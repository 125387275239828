<!-- pages/MinePage.vue -->
<template>
    <div class="page mine-page">
      <div class="mine-header">
        <van-image
          class="avatar"
          round
          width="50"
          height="50"
          :src="require('@/assets/user-avatar.jpg')"
        />
        <div class="nickname" @click="onNicknameClick">
          {{ loggedIn ? userInfo.nickname : '立即登录' }}
        </div>
      </div>
      <van-cell-group>
        <van-cell
          title="意见反馈"
          is-link
          @click="$emit('goView','feedback')"
        />
        <!-- 新增：退出登录，仅在已登录状态下显示 -->
        <!-- <van-cell
          v-if="loggedIn"
          title="退出登录"
          is-link
          @click="onLogout"
        /> -->
        <!-- 新增“注销账号”Cell -->
        <van-cell
          v-if="loggedIn"
          title="注销账号"
          is-link
          class="delete-account"
          @click="onDeleteAccount"
        />
      </van-cell-group>
    </div>
  </template>
  
  <script>
import axios from 'axios';
import { Dialog, Toast } from 'vant';

  export default {
    name: 'MinePage',
    props: {
      userInfo: { type: Object, default: () => ({}) },
      // loggedIn: { type: Boolean, default: !!sessionStorage.getItem('token') },
    },
    data() {
      return {
        loggedIn: !!sessionStorage.getItem('token')
      }
    },
    methods: {
      onNicknameClick() {
        if (!this.loggedIn) {
          // 未登录，跳转到登录页面
          this.$emit('goView', 'login');
        }
      },
      onLogout() {
        // 通知父组件退出登录处理（如清空登录状态、用户信息等）
        this.$emit('logout');
      },
      onDeleteAccount() {
      // 弹出确认对话框
      Dialog.confirm({
        title: '确认注销账号',
        message: '注销账号后，所有数据将被删除且无法恢复。确定继续吗？',
        zIndex: 999229,
      })
        .then(() => {
          // 从 sessionStorage 获取 token
          const token = sessionStorage.getItem('token');
          // 调用注销账号接口
          axios
            .post('https://mobile.dingdigit.com/webchat/v1/user/remove', { token })
            .then((res) => {
              if (res.data && res.data.code === 100) {
                Toast.success(res.data.data || '账号已注销');
                // 注销成功后清除 token
                sessionStorage.removeItem('token');
                // 跳转到登录页面
                this.$emit('goView', 'login');
              } else {
                Toast.fail('注销失败');
              }
            })
            .catch((err) => {
              Toast.fail('注销失败');
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }
    },
  };
  </script>
  
  <style scoped>
  .page {
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
    box-sizing: border-box;
  }
  .mine-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .avatar {
    border-radius: 50%;
  }
  .nickname {
    margin-left: 10px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
  }
  </style>

  <style>
  .van-dialog-wrapper {
    z-index: 10000 !important;
  }
  </style>
  