<!-- pages/MessagePage.vue -->
<template>
  <div class="message-page">
    <!-- <div class="header">消息</div> -->
    <div class="header">
      <div class="header-title">消息</div>
      <van-icon name="cross" class="close-icon" @click="$emit('close')" />
    </div>
    <div class="message-item" @click="$emit('goView', 'chat')">
      <div class="ai-avatar">AI</div>
      <div class="message-content">
        <div class="message-title">惦记</div>
        <div class="message-text">
          基于惦记多年业务积累以及LLM技术...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessagePage",
};
</script>

<style scoped>
.message-page {
  width: 100%;
  height: 100%;
  background: #fff;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* 标题和关闭按钮分左右排列 */
  padding: 15px;
  border-bottom: 1px solid #eee;
}
.header-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-align: center;
  flex: 1;
}

.close-icon {
  font-size: 20px;
  color: #999;
}

.message-item {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.ai-avatar {
  font-weight: bold;
}

.message-item {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.ai-avatar {
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  background: linear-gradient(45deg, #ff66ff, #6699ff);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
}

.message-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.message-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.message-text {
  font-size: 14px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.close-icon {
  font-size: 20px;
  color: #999;
  cursor: pointer;
}

</style>
