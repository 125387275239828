<!-- pages/RegisterPage.vue -->
<template>
  <div class="page register-page">
    <h2 class="page-title">注册</h2>
    <van-field
      v-model="phone"
      placeholder="请输入手机号"
      clearable
      class="input-field"
    />
    <div class="verify-box">
      <van-field
        v-model="verifyCode"
        placeholder="请输入验证码"
        clearable
        class="verify-input"
      />
      <van-button
        type="info"
        size="small"
        :disabled="countdown > 0"
        @click="onSendCode"
      >
        {{ countdown > 0 ? countdown + 's' : '发送验证码' }}
      </van-button>
    </div>
    <van-field
      v-model="idCard"
      placeholder="请输入身份证号"
      clearable
      class="input-field"
    />
    <van-field
      v-model="realName"
      placeholder="请输入真实姓名"
      clearable
      class="input-field"
    />
    <van-field
      v-model="password"
      type="password"
      placeholder="请输入密码"
      clearable
      class="input-field"
    />
    <van-field
      v-model="nickname"
      placeholder="请输入昵称"
      clearable
      class="input-field"
    />
    <van-button
      type="primary"
      block
      class="submit-button"
      @click="onRegister"
    >
      注册
    </van-button>
    <div class="login-tip" @click="$emit('goView','login')">
      已有账号？立即登录
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Toast } from 'vant';

export default {
  name: 'RegisterPage',
  data() {
    return {
      phone: '',
      verifyCode: '',
      idCard: '',
      realName: '',
      password: '',
      nickname: '',
      countdown: 0,
      timer: null,
    };
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  },
  methods: {
    onSendCode() {
      if (!/^1\d{10}$/.test(this.phone)) {
        Toast('请输入正确的手机号');
        return;
      }
      axios
        .post('https://mobile.dingdigit.com/webchat/v1/vcode', {
          type: 0,
          phone: this.phone,
        })
        .then((res) => {
          if (res.data && res.data.code === 100) {
            Toast.success('验证码已发送');
            this.countdown = 60;
            this.timer = setInterval(() => {
              if (this.countdown > 0) {
                this.countdown--;
              } else {
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          } else {
            Toast.fail('发送验证码失败');
          }
        })
        .catch((err) => {
          Toast.fail('发送验证码失败');
          console.error(err);
        });
    },
    onRegister() {
      if (
        !this.phone ||
        !this.verifyCode ||
        !this.idCard ||
        !this.realName ||
        !this.password ||
        !this.nickname
      ) {
        Toast('请填写完整信息');
        return;
      }
      axios
        .post('https://mobile.dingdigit.com/webchat/v1/register', {
          code: this.verifyCode,
          name: this.realName,
          phone: this.phone,
          ident: this.idCard,
          nickname: this.nickname,
          password: this.password,
        })
        .then((res) => {
          if (res.data && res.data.code === 100) {
            // 获取 token 并存入 sessionStorage
            const token = res.data.data.token;
            sessionStorage.setItem('token', token);
            Toast.success('注册成功');
            // 注册成功后跳转到聊天页面
            this.$emit('goView', 'chat');
          } else {
            Toast.fail('注册失败');
          }
        })
        .catch((err) => {
          Toast.fail('注册失败');
          console.error(err);
        });
    },
  },
};
</script>

<style scoped>
.page {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}
.page-title {
  font-size: 22px;
  margin-bottom: 20px;
  text-align: center;
}
.input-field {
  margin-bottom: 15px;
}
.verify-box {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}
.verify-input {
  flex: 1;
  margin-right: 10px;
}
.submit-button {
  margin-top: 10px;
}
.login-tip {
  margin-top: 15px;
  text-align: center;
  color: #3f81f8;
  cursor: pointer;
  font-size: 14px;
}
</style>
